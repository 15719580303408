<template>
  <div class="list-box">
    <el-tabs v-model="activeName"
             @tab-click="handleClick">
      <el-tab-pane label="组织"
                   name="first"></el-tab-pane>
      <el-tab-pane label="客户"
                   name="second"></el-tab-pane>
    </el-tabs>
    <el-row>
      <el-col :span="20"
              :offset="1">
        <h5>包含</h5>
        <vxe-toolbar v-if="notView">
          <template v-slot:buttons>
            <vxe-button @click="addTable('')">添加</vxe-button>
            <vxe-button @click="deleteTable('first')">删除</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign"
                   @checkbox-all="
        ({ records }) => selectAllEvent('firstSelect', records)
      "
                   @checkbox-change="
        ({ records }) => selectChangeEvent('firstSelect', records)
      "
                   :data="firstTableData">
          <vxe-table-column type="checkbox"
                            width="50"></vxe-table-column>
          <vxe-table-column v-for="(config, index) in firstColumn"
                            :key="index"
                            v-bind="config"></vxe-table-column>
        </vxe-table>
        <h5>非包含</h5>
        <vxe-toolbar v-if="notView">
          <template v-slot:buttons>
            <vxe-button @click="addTable('non')">添加</vxe-button>
            <vxe-button @click="deleteTable('second')">删除</vxe-button>
          </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign"
                   @checkbox-all="
        ({ records }) => selectAllEvent('secondSelect', records)
      "
                   @checkbox-change="
        ({ records }) => selectChangeEvent('secondSelect', records)
      "
                   :data="secondTableData">
          <vxe-table-column type="checkbox"
                            width="50"></vxe-table-column>
          <vxe-table-column v-for="(config, index) in firstColumn"
                            :key="index"
                            v-bind="config"></vxe-table-column>
        </vxe-table>
      </el-col>
    </el-row>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';

const orgColumn = [
  { field: 'orgCode', title: '组织编码' },
  { field: 'orgName', title: '组织名称' },
];
const cusColumn = [
  { field: 'customerCode', title: '客户编码' },
  { field: 'customerName', title: '客户名称' },
];
export default {
  name: 'NoticeManageTableForm',
  components: {
    SelectConfig,
  },
  props: {
    value: Object,
    notView: Boolean,
  },
  watch: {
    value() {
      if (this.tabType === 'first') {
        this.firstTableData = this.value.orgs;
        this.secondTableData = this.value.nonOrgs;
      }
    },
  },
  data() {
    return {
      modalConfig: {}, // 弹框信息
      formConfig: {
        tableType: 'orgUrl',
      }, // 弹框数据
      formName: 'Form',
      activeName: 'first',
      allAlign: null,
      firstColumn: orgColumn,
      firstTableData: [],
      secondTableData: [],
      modalType: '', // 弹框包含非包含
      tabType: 'first',
      firstSelect: [],
      secondSelect: [],
    };
  },
  created() {
    // this.firstTableData = this.value.orgs;
    // this.secondTableData = this.value.nonOrgs;
  },
  methods: {
    handleClick(tab) {
      this.tabType = tab.name;
      if (tab.name === 'second') {
        this.firstColumn = cusColumn;
        this.firstTableData = this.value.customers;
        this.secondTableData = this.value.nonCustomers;
      } else if (tab.name === 'first') {
        this.firstColumn = orgColumn;
        this.firstTableData = this.value.orgs;
        this.secondTableData = this.value.nonOrgs;
      }
    },
    addTable(val) {
      this.modalType = val || '';
      let params;
      if (this.tabType === 'first') {
        const selectionList = [];
        const list = val ? this.secondTableData : this.firstTableData;
        params = {
          functionCode: 'dms_noice_org',
          data: list,
          selectionList,
          idKey: 'orgCode',
        };
      } else if (this.tabType === 'second') {
        const selectionList = [];
        const list = val ? this.secondTableData : this.firstTableData;
        params = {
          functionCode: 'dms_noitce_cus',
          data: list,
          selectionList,
          idKey: 'customerCode',
        };
      }
      this.$refs.selectConfig.openSelectModal(params);

      // this.openModal();
    },
    onGetSelect(val) {
      if (this.modalType) {
        if (this.tabType === 'second') {
          this.value.nonCustomers = val;
        } else if (this.tabType === 'first') {
          this.value.nonOrgs = val;
        }
        this.secondTableData = val;
      } else {
        if (this.tabType === 'second') {
          this.value.customers = val;
        } else if (this.tabType === 'first') {
          this.value.orgs = val;
        }
        this.firstTableData = val;
      }
    },
    deleteTable(val) {
      const list = [];
      const code = this.tabType === 'second' ? 'customerCode' : 'orgCode';
      this[`${val}TableData`].forEach((v) => {
        let notSelect = true;
        if (this[`${val}Select`].filter((a) => a[code] === v[code]).length > 0) {
          notSelect = false;
        }
        if (notSelect) {
          list.push(v);
        }
      });
      this[`${val}TableData`] = list;
      this[`${val}Select`] = [];
      if (this.tabType === 'second') {
        this.value.customers = this.firstTableData;
        this.value.nonCustomers = this.secondTableData;
      } else if (this.tabType === 'first') {
        this.value.orgs = this.firstTableData;
        this.value.nonOrgs = this.secondTableData;
      }
    },
    selectChangeEvent(val, records) {
      this[val] = records;
    },
    selectAllEvent(val, records) {
      this[val] = records;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped>
</style>
