<script>
import TablePage from '../../../../../../components/table_page';
import configs from './data';
import Form from '../form';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    parentCode: String,
  },
  data() {
    return {
      requestUrl: '',
      // params: { parentCode: '' },
      configs,
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  watch: {
    parentCode(n) {
      this.params.parentCode = n;
      this.formConfig.parentCode = n;
      this.getList();
    },
  },
  async created() {
    const columns = await this.getConfigList('dms-company-info-table');
    columns.map((v) => {
      const rowData = v;
      if (rowData.field === 'createDate') {
        rowData.formatter = ({ row }) => `${row.createDate} ${row.createDateSecond}`;
      } else if (rowData.field === 'updateDate') {
        rowData.formatter = ({ row }) => (row.updateDate == null ? '' : `${row.updateDate} ${row.updateDateSecond}`);
      }
      return rowData;
    });
  },
  methods: {
    // beforeSearchEvent(val) {
    //   console.log(val.$event.type);
    // },
    modalClick({ val, row }) {
      if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig.selection = [row.id];
        this.openFull();
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.selection = [];
        this.openFull();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig.selection = [row.id];
        this.openFull();
      }
      return true;
    },
  },
};
</script>
