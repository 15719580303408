<template>
  <div class="home">
      <TablePage :parentCode="parentCode"/>

  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'NoticeManage',
  components: {
    TablePage,
  },
  data() {
    return {
      parentCode: '',
    };
  },
  created() {},
  methods: {
    changeParentCode(val) {
      this.parentCode = val.parentCode;
    },
  },
};
</script>
